<template>
  <section>
    <h1 v-if="isPatient">{{ $t('dashboard.titles.main') }}</h1>
    <h2 v-if="isPatient">{{ today }}</h2>

    <component :is="componentName"/>
  </section>
</template>

<script>
import { JWTTranslation } from '@/store/modules/Security'
import { myStatus } from '@/store/modules/User'

export default {
  name: 'Dashboard',

  components: {
    PatientDashboard: () => import('@/components/Dashboard/Patient'),
    AdminAdminTecResearcherDoctorDashboard: () => import('@/components/Admin/UserList/UserList'),
  },

  data: () => ({
    componentName: '',
  }),

  computed: {
    isPatient () {
      return myStatus(['PAT'])
    },
    today () {
      const today = (new Date()).toLocaleDateString(
          navigator.language || 'fr-FR',
          {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          },
      )

      return `${today.charAt(0)
                     .toUpperCase()}${today.slice(1)}`
    },
  },

  created () {
    switch (JWTTranslation(localStorage.getItem('JWT_ACCESS')).body.profileName) {
      case 'ADM':
      case 'TEC':
      case 'CHR':
      case 'MED':
        this.componentName = 'AdminAdminTecResearcherDoctorDashboard'
        break
      case 'PAT':
        this.componentName = 'PatientDashboard'
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  h2 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 15px;
  }
}
</style>
